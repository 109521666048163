































































































import { Component, Vue } from 'vue-property-decorator';
import Button from '@/components/ui/Button.vue';
import { PhoneIcon } from 'vue-feather-icons';
import { MailIcon } from 'vue-feather-icons';
import { metaInfo } from '@/utils/seo/metaInfo';
import { buildUrl } from '@/utils/url';
import { RENTAL } from '@/models/carType';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from '@/utils/consts';

@Component({
  components: {
    Button,
    PhoneIcon,
    MailIcon
  },
  metaInfo: metaInfo({
    title: 'How to rent a van | Kakadoo',
    url: buildUrl('/how-it-works'),
    lang: 'en'
  })
})
export default class HowItWorks extends Vue {
  scrollToReturn() {
    const el = document.getElementById('how-to-return') as HTMLElement;

    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  goToVans() {
    if (this.$gtag) {
      this.$gtag.event(`see-availability-how-it-works-eng-button`);
    }
    this.$router.push({
      path: RENTAL,
      query: {
        from: moment().format(DEFAULT_DATE_FORMAT),
        to: moment().format(DEFAULT_DATE_FORMAT)
      }
    });
  }
}
